import { memo } from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';

import { Film, getCountryForFilm } from '@app/api/resources/Film';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import ValueAtBreakpoint from '@app/components/breakpoints/ValueAtBreakpoint';

const FilmDirectors = dynamic(
  () =>
    import(
      /* webpackChunkName: "FilmDirectors" */
      '@app/components/FilmDirectors'
    ),
  { ssr: false },
);

const DirectorCountryYear = ({
  film,
  maxDirectorsToShow,
  mobileFontSize = '12px',
  desktopFontSize,
}: {
  film: Film;
  maxDirectorsToShow?: number;
  mobileFontSize?: string;
  desktopFontSize?: string;
}) => {
  const currentLanguage = useAppSelector(state => state.user.currentLanguage);
  const hasFilmDirector = film && film?.directors && film.directors.length > 0;
  const country = getCountryForFilm(film);

  return (
    <DirectorYear
      capitalise={currentLanguage !== 'tr'}
      mobileFontSize={mobileFontSize}
      desktopFontSize={desktopFontSize}
      data-testid="director-and-year"
    >
      {hasFilmDirector ? (
        <ValueAtBreakpoint
          defaultValue={2}
          valueAtMobile={1}
          valueAtTablet={2}
          valueAtDesktop={2}
          valueAtWide={2}
        >
          {({ currentValue: numberOfDirectors }) => (
            <FilmDirectors
              directors={film?.directors}
              maxToShow={maxDirectorsToShow || (numberOfDirectors as number)}
            />
          )}
        </ValueAtBreakpoint>
      ) : null}
      {film?.year && hasFilmDirector && ' '}
      {country ? <CountryContainer>{country}</CountryContainer> : null}
      {film?.year ? <span> {film.year}</span> : null}
    </DirectorYear>
  );
};

export default memo(DirectorCountryYear);

const DirectorYear = styled.div<{
  capitalise: boolean;
  mobileFontSize: string;
  desktopFontSize: string;
}>`
  text-transform: ${props => (props.capitalise ? 'uppercase' : 'none')};
  font-size: ${props => props.mobileFontSize};
  font-weight: lighter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    font-size: ${props => props.desktopFontSize || props.mobileFontSize};
    line-height: ${props => props.desktopFontSize || props.mobileFontSize};
  }
  @media (min-width: ${props => props.theme.mqNew.wideLarge}) {
    font-size: 14px;
    line-height: 14px;
  }
`;

const CountryContainer = styled.span`
  display: none;
  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    display: inline;
  }
`;
