import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import { FilmId } from '@app/api/resources/Film';
import { getRatingsForFilmsFromCurrentUserByFilmIds } from '@app/api/resources/Ratings';

import {
  arrayToObjectKeyedById,
  filterObjectByKeys,
  hasOwnProperty,
} from '@app/services/utils';

import { addUserRatings } from '@app/actions/UserActions';
import { RootState } from '@app/reducers';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const selectRatings = () =>
  createSelector(
    (state: RootState) => state.user.usersFilmRatings,
    (_, filmIds: FilmId[]) => filmIds,
    (usersFilmRatings, filmIds: FilmId[]) =>
      filterObjectByKeys(usersFilmRatings, filmIds),
  );

const usePopulateUserRatingForFilms = (filmIds: FilmId[]) => {
  const [isError, setIsError] = useState(false);
  const memoizedSelectRatings = useMemo(selectRatings, []);

  const { httpContext, isAuthenticated, cachedRatings } = useAppSelector(
    state => ({
      httpContext: state.appState.httpContext,
      isAuthenticated: state.user.isAuthenticated,
      cachedRatings: memoizedSelectRatings(state, filmIds),
    }),
    shallowEqual,
  );

  const dispatch = useDispatch();

  const fetchRatings = async () => {
    const filmIdsToInitialise = filmIds.filter(
      filmId => !hasOwnProperty(cachedRatings, filmId),
    );
    try {
      const { data: fetchedRatings } =
        await getRatingsForFilmsFromCurrentUserByFilmIds(
          httpContext,
          filmIdsToInitialise,
        );
      const fetchedRatingsByFilmId = arrayToObjectKeyedById(
        fetchedRatings,
        'film_id',
      );
      const fetchedRatingsByFilmIdIncludingEmpty = {};
      filmIdsToInitialise.forEach(filmId => {
        fetchedRatingsByFilmIdIncludingEmpty[filmId] =
          fetchedRatingsByFilmId[filmId] || null;
      });
      dispatch(addUserRatings(fetchedRatingsByFilmIdIncludingEmpty));
    } catch (error) {
      setIsError(true);
      console.error(error);
    }
  };

  const filmIdsUniqueKey = filmIds ? [...filmIds].sort().join('-') : '';
  const allRelevantRatingsAreCached =
    filmIds && Object.keys(cachedRatings).length === filmIds.length;

  useEffect(() => {
    if (filmIdsUniqueKey && !allRelevantRatingsAreCached && isAuthenticated) {
      fetchRatings();
    }
  }, [filmIdsUniqueKey]);

  const isLoading = !allRelevantRatingsAreCached;

  return [isLoading, isError];
};

export default usePopulateUserRatingForFilms;
