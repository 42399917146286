import { PlayerConfig } from '@app/services/clpp';

import * as actionTypes from '@app/actionTypes';
import { PreviewClipPlayerStatus } from '@app/reducers/FilmTileReducer';

export const setPreviewClipPlayer = (
  videoElementId: string,
  previewClipId: number,
  filmId: number | string,
  previewClipSrcUrl: Record<string, string>[],
  overrideConfig?: Partial<PlayerConfig>,
) => ({
  type: actionTypes.SET_PREVIEW_CLIP_PLAYER,
  payload: {
    videoElementId,
    previewClipId,
    filmId,
    previewClipSrcUrl,
    overrideConfig,
  },
});

export const clearPreviewClipPlayer = () => ({
  type: actionTypes.CLEAR_PREVIEW_CLIP_PLAYER,
});

export const setPreviewClipPlayerStatus = (
  status: PreviewClipPlayerStatus,
) => ({
  type: actionTypes.SET_PREVIEW_CLIP_PLAYER_STATUS,
  payload: {
    status,
  },
});

export const setPreviewClipPlayerMuted = (muted: boolean) => ({
  type: actionTypes.SET_PREVIEW_CLIP_PLAYER_MUTED,
  payload: {
    muted,
  },
});

export const setPreviewClipPlayerDuration = (duration: number) => ({
  type: actionTypes.SET_PREVIEW_CLIP_PLAYER_DURATION,
  payload: {
    duration,
  },
});

export const setPreviewClipPlayerSubs = (hasSubs: boolean) => ({
  type: actionTypes.SET_PREVIEW_CLIP_PLAYER_SUBS,
  payload: {
    hasSubs,
  },
});
