import styled from '@emotion/styled';

import { getImageUrl } from '@app/services/images';

import mubiTheme from '@app/themes/mubi-theme';

import ImageBase from '@app/components/ImageBase';
import PreloadTags from '@app/components/responsive-images/ResponsivePicturePreloadTags';

type ResponsivePictureProps = {
  src: string;
  alt: string;
  sizeInPixels: number;
  responsiveSizesInPixels?: {
    desktopAndUp?: number;
    tablet?: number;
    mobile?: number;
  };
  objectFit?: string;
  backgroundPosition?: string;
  shouldPreload?: boolean;
  className?: string;
};

const ResponsivePicture = ({
  src,
  alt,
  sizeInPixels,
  objectFit,
  backgroundPosition,
  responsiveSizesInPixels,
  shouldPreload = false,
  className,
}: ResponsivePictureProps) => {
  let desktopAndUp: number, tablet: number, mobile: number;

  if (responsiveSizesInPixels) {
    ({ desktopAndUp, tablet, mobile } = responsiveSizesInPixels);
  }

  return (
    <>
      {shouldPreload && (
        <PreloadTags
          src={src}
          sizeInPixels={sizeInPixels}
          responsiveSizesInPixels={responsiveSizesInPixels}
        />
      )}

      <Picture
        className={className}
        objectFit={objectFit}
        backgroundPosition={backgroundPosition}
      >
        {desktopAndUp && (
          <source
            srcSet={getImageUrl(src, desktopAndUp)}
            media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.desktop})`}
          />
        )}
        {tablet && !desktopAndUp && (
          <source
            srcSet={getImageUrl(src, tablet)}
            media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.tablet})`}
          />
        )}
        {tablet && desktopAndUp && (
          <source
            srcSet={getImageUrl(src, tablet)}
            media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.tablet}) and (max-width: ${mubiTheme.responsiveImageBreakpoints.desktop})`}
          />
        )}
        {mobile && !tablet && !desktopAndUp && (
          <source
            srcSet={getImageUrl(src, mobile)}
            media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.mobile})`}
          />
        )}
        {mobile && !tablet && desktopAndUp && (
          <source
            srcSet={getImageUrl(src, mobile)}
            media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.mobile}) and (max-width: ${mubiTheme.responsiveImageBreakpoints.desktop})`}
          />
        )}
        {mobile && tablet && (
          <source
            srcSet={getImageUrl(src, mobile)}
            media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.mobile}) and (max-width: ${mubiTheme.responsiveImageBreakpoints.tablet})`}
          />
        )}
        <ImageBase
          alt={alt}
          loading={shouldPreload ? 'eager' : 'lazy'}
          src={getImageUrl(src, sizeInPixels)}
        />
      </Picture>
    </>
  );
};

const Picture = styled.picture<{
  objectFit: string;
  backgroundPosition: string;
}>`
  img {
    ${props => (props.objectFit ? `object-fit: ${props.objectFit};` : '')}
    ${props =>
      props.backgroundPosition
        ? `object-position: ${props.backgroundPosition};`
        : ''}
  }
`;

export default ResponsivePicture;
