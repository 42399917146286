import { useCallback, useEffect, useRef } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { LocalePath } from '@app/api/services/language';
import PlayerInterface from '@app/services/PlayerInterface';

import {
  clearPreviewClipPlayer,
  setPreviewClipPlayerDuration,
  setPreviewClipPlayerMuted,
  setPreviewClipPlayerStatus,
} from '@app/actions/FilmTileActions';
import { PreviewClipPlayer } from '@app/reducers/FilmTileReducer';

import useAppSelector from '@app/hooks/utils/useAppSelector';

const player = new PlayerInterface();

const usePlayerOrchestrator = (): PlayerInterface => {
  const dispatch = useDispatch();
  const router = useRouter();
  const initialisingPlayer = useRef(false);
  const interruptPlayerInit = useRef(false);

  const {
    previewClipPlayer,
    currentLanguage,
  }: {
    previewClipPlayer: PreviewClipPlayer;
    currentLanguage: LocalePath;
  } = useAppSelector(
    state => ({
      previewClipPlayer: state.filmTile.previewClipPlayer,
      currentLanguage: state.user.currentLanguage,
    }),
    shallowEqual,
  );

  const { startTrackingDuration, stopTrackingDuration } =
    useTrackPreviewClipWatchDuration();

  const setMuted = useCallback(() => {
    player.muted =
      previewClipPlayer.overrideConfig?.muted || previewClipPlayer.isMuted;
  }, [previewClipPlayer.isMuted, previewClipPlayer.overrideConfig?.muted]);

  const onPlayerStateChange = useCallback(({ currentState }) => {
    if (currentState === 'ended') {
      dispatch(setPreviewClipPlayerStatus('ended'));
    }
    if (currentState === 'error') {
      dispatch(setPreviewClipPlayerStatus('error'));
    }
    if (currentState === 'playing') {
      startTrackingDuration();
    }
  }, []);

  const doDestroyPlayer = async () => {
    initialisingPlayer.current = false;
    stopTrackingDuration();
    player.off('statechanged', onPlayerStateChange);
    player.destroy();
  };

  const handleRouteChange = useCallback(() => {
    if (player.isPlayerInitialized) {
      doDestroyPlayer();
    }
    dispatch(clearPreviewClipPlayer());
  }, []);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      handleRouteChange();
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  useEffect(() => {
    if (previewClipPlayer.videoElementId === null) {
      doDestroyPlayer();
    }
  }, [previewClipPlayer.videoElementId]);

  useEffect(() => {
    if (previewClipPlayer.hasSubs) {
      player.setTextTrack(currentLanguage);
    } else {
      player.clearTextTrack();
    }
  }, [currentLanguage, previewClipPlayer.hasSubs]);

  useEffect(() => {
    setMuted();
  }, [previewClipPlayer.isMuted]);

  const doPlay = async () => {
    if (!interruptPlayerInit.current) {
      try {
        await player.doPlay({
          isMuted: previewClipPlayer.isMuted,
        });
        dispatch(setPreviewClipPlayerStatus('success'));
        initialisingPlayer.current = false;
      } catch (error) {
        doDestroyPlayer();
        interruptPlayerInit.current = false;
      }
    } else {
      doDestroyPlayer();
      interruptPlayerInit.current = false;
    }
  };

  useEffect(() => {
    const initPlayer = async () => {
      const el = document.getElementById(
        previewClipPlayer.videoElementId,
      ) as HTMLVideoElement;
      if (!el) {
        console.log('initPlayer: element not found');
        return;
      }
      try {
        initialisingPlayer.current = true;
        await player.init(el, previewClipPlayer.overrideConfig);
        player.on('statechanged', onPlayerStateChange);

        await player.load({
          source: previewClipPlayer.previewClipSrcUrl,
          isMuted:
            previewClipPlayer?.overrideConfig?.muted ||
            previewClipPlayer.isMuted,
          preferredTextLanguage: currentLanguage,
        });
        setTimeout(() => {
          doPlay();
        });
      } catch (error) {
        if (error?.code === 7000) {
          // Error code info from Castlabs:
          // "The call to Player.load() was interrupted by a call to Player.release() or another call to Player.load().""
          //
          // We can ignore this, a user moved their mouse out of the film tile whilst the player was initialising.
        } else if (!previewClipPlayer.isMuted) {
          // If we're trying to play with audio, but not allowed by the browser,
          // we'll try to start the player with the sound disabled
          try {
            dispatch(setPreviewClipPlayerMuted(true));
            await player.load({
              source: previewClipPlayer.previewClipSrcUrl,
              isMuted: true,
              preferredTextLanguage: currentLanguage,
            });
            setTimeout(() => {
              doPlay();
            });
          } catch (error) {
            dispatch(setPreviewClipPlayerStatus('error'));
          }
        } else {
          dispatch(setPreviewClipPlayerStatus('error'));
        }
      }
    };

    if (
      previewClipPlayer.videoElementId &&
      previewClipPlayer.previewClipSrcUrl
    ) {
      initPlayer();
    } else {
      if (initialisingPlayer.current) {
        // This hook has run a second time whilst we are loading the player.
        interruptPlayerInit.current = true;
      }
    }
  }, [previewClipPlayer.videoElementId]);

  return player;
};

const useTrackPreviewClipWatchDuration = () => {
  const dispatch = useDispatch();
  const intervalId = useRef(null);

  const doTrackingDuration = () => {
    dispatch(setPreviewClipPlayerDuration(Math.round(player.currentTime)));
  };

  const startTrackingDuration = () => {
    stopTrackingDuration();
    intervalId.current = setInterval(doTrackingDuration, 1000);
  };

  const stopTrackingDuration = () => {
    if (intervalId.current !== null) {
      clearInterval(intervalId.current);
      intervalId.current = null;
    }
  };

  return {
    startTrackingDuration,
    stopTrackingDuration,
  };
};

export default usePlayerOrchestrator;
