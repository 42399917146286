import { checkPathnameArrayIncludesPathname } from '@app/services/routeHelpers';

import usePreviousPath from '@app/hooks/utils/usePreviousPath';

const useShouldShowBackButton = ({
  allowedBackwardPaths,
}: {
  allowedBackwardPaths: string[];
}): boolean => {
  const previousPath = usePreviousPath();

  return checkPathnameArrayIncludesPathname(
    allowedBackwardPaths,
    previousPath?.previousPathname,
  );
};

export default useShouldShowBackButton;
