import { ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { isClient } from '@app/services/utils';

import StopPropagation from '@app/components/StopPropagation';

type ModalProps = {
  baseNode?: HTMLDivElement | null;
  children: ReactNode;
  isOpen: boolean;
  doClose?: () => void;
  adjustForHeader?: boolean;
  withOverlay?: boolean;
  overlayTransparency?: number;
  withPopAnimation?: boolean;
  fullscreenModal?: boolean;
  fullscreenOnMobile?: boolean;
  alignAtTop?: boolean;
  className?: string;
};

const Modal = ({
  baseNode = null,
  children,
  isOpen,
  doClose = null,
  adjustForHeader = true,
  withOverlay = false,
  overlayTransparency = 0.5,
  withPopAnimation = false,
  fullscreenModal = false,
  fullscreenOnMobile = true,
  alignAtTop = false,
  className = '',
}: ModalProps) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isClient()) {
    return null;
  }

  const renderPortal = (open: boolean) =>
    createPortal(
      <CSSTransition
        in={open}
        appear={open}
        classNames="appear"
        timeout={200}
        unmountOnExit
      >
        <Overlay
          withOverlay={withOverlay}
          overlayTransparency={overlayTransparency}
          withPopAnimation={withPopAnimation}
          adjustForHeader={adjustForHeader}
          alignAtTop={alignAtTop}
          fullscreenModal={fullscreenModal}
          onClick={doClose}
          className={className}
        >
          <StopPropagation>
            <StyledModal fullscreenOnMobile={fullscreenOnMobile}>
              {children}
            </StyledModal>
          </StopPropagation>
        </Overlay>
      </CSSTransition>,
      baseNode || document.body,
    );

  return renderPortal(isOpen);
};

export default Modal;

const adjustedHeaderStyles = props =>
  props.adjustForHeader
    ? css`
        @media (min-width: ${props.theme.mqNew.desktop}) {
          top: 50px;
          height: calc(100% - 50px);
        }
      `
    : css``;

const alignAtTopStyles = props =>
  props.alignAtTop
    ? css`
        align-items: flex-start;
      `
    : css``;

const animationFadeIn = props => css`
  display: none;
  transition: opacity 0.2s;
  &.appear-enter {
    display: ${props.fullscreenOnMobile ? 'block' : 'flex'};
    @media (min-width: ${props.theme.mqNew.desktop}) {
      display: ${props.fullscreenModal ? 'block' : 'flex'};
    }
    opacity: 0;
  }

  &.appear-enter.appear-enter-active {
    opacity: 1;
  }

  &.appear-enter-done {
    display: ${props.fullscreenOnMobile ? 'block' : 'flex'};
    @media (min-width: ${props.theme.mqNew.desktop}) {
      display: ${props.fullscreenModal ? 'block' : 'flex'};
    }
  }

  &.appear-exit {
    display: ${props.fullscreenOnMobile ? 'block' : 'flex'};
    @media (min-width: ${props.theme.mqNew.desktop}) {
      display: ${props.fullscreenModal ? 'block' : 'flex'};
    }
    opacity: 1;
  }

  &.appear-exit.appear-exit-active {
    opacity: 0;
  }
`;

const animationPopIn = props => css`
  display: none;
  transition: opacity 0.2s;

  ${StyledModal} {
    transition: transform 0.2s;
  }

  &.appear-enter {
    display: ${props.fullscreenOnMobile ? 'block' : 'flex'};
    @media (min-width: ${props.theme.mqNew.desktop}) {
      display: ${props.fullscreenModal ? 'block' : 'flex'};
    }

    opacity: 0;

    ${StyledModal} {
      transform: scale(1.25, 1.25);
    }
  }

  &.appear-enter.appear-enter-active {
    opacity: 1;

    ${StyledModal} {
      transform: scale(1, 1);
    }
  }

  &.appear-enter-done {
    display: ${props.fullscreenOnMobile ? 'block' : 'flex'};
    @media (min-width: ${props.theme.mqNew.desktop}) {
      display: ${props.fullscreenModal ? 'block' : 'flex'};
    }
  }

  &.appear-exit {
    display: ${props.fullscreenOnMobile ? 'block' : 'flex'};
    @media (min-width: ${props.theme.mqNew.desktop}) {
      display: ${props.fullscreenModal ? 'block' : 'flex'};
    }
    opacity: 1;

    ${StyledModal} {
      transform: scale(0.9, 0.9);
    }
  }

  &.appear-exit.appear-exit-active {
    opacity: 0;
  }
`;

const animationStyles = props =>
  props.withPopAnimation ? animationPopIn : animationFadeIn;

type OverlayProps = {
  adjustForHeader?: boolean;
  withOverlay?: boolean;
  overlayTransparency?: number;
  withPopAnimation?: boolean;
  fullscreenModal?: boolean;
  fullscreenOnMobile?: boolean;
  alignAtTop?: boolean;
  className?: string;
};
const Overlay = styled.div<OverlayProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;

  overflow: auto;
  outline: none;

  align-items: center;
  justify-content: center;

  /* ios momentum scrolling */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${props =>
    props.withOverlay
      ? `background-color: rgba(0, 0, 0, ${props.overlayTransparency});`
      : ''}

  ${animationStyles}

  ${adjustedHeaderStyles}

  ${alignAtTopStyles}
`;

type StyledProps = {
  fullscreenOnMobile?: boolean;
};
const StyledModal = styled.div<StyledProps>``;
