import Head from 'next/head';

import { getImageUrl } from '@app/services/images';

import mubiTheme from '@app/themes/mubi-theme';

type ResponsivePicturePreloadTagsProps = {
  src: string;
  sizeInPixels: number;
  responsiveSizesInPixels?: {
    desktopAndUp?: number;
    tablet?: number;
    mobile?: number;
  };
};

const ResponsivePicturePreloadTags = ({
  src,
  sizeInPixels,
  responsiveSizesInPixels,
}: ResponsivePicturePreloadTagsProps) => {
  let desktopAndUp: number, tablet: number, mobile: number;

  if (responsiveSizesInPixels) {
    ({ desktopAndUp, tablet, mobile } = responsiveSizesInPixels);
  }

  return (
    <Head>
      {desktopAndUp && (
        <link
          rel="preload"
          href={getImageUrl(src, desktopAndUp)}
          as="image"
          media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.desktop})`}
        />
      )}
      {tablet && !desktopAndUp && (
        <link
          rel="preload"
          href={getImageUrl(src, tablet)}
          as="image"
          media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.tablet})`}
        />
      )}
      {tablet && desktopAndUp && (
        <link
          rel="preload"
          href={getImageUrl(src, tablet)}
          as="image"
          media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.tablet}) and (max-width: ${mubiTheme.responsiveImageBreakpoints.desktop})`}
        />
      )}
      {mobile && !tablet && !desktopAndUp && (
        <link
          rel="preload"
          href={getImageUrl(src, mobile)}
          as="image"
          media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.mobile})`}
        />
      )}
      {mobile && !tablet && desktopAndUp && (
        <link
          rel="preload"
          href={getImageUrl(src, mobile)}
          as="image"
          media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.mobile}) and (max-width: ${mubiTheme.responsiveImageBreakpoints.desktop})`}
        />
      )}
      {mobile && tablet && (
        <link
          rel="preload"
          href={getImageUrl(src, mobile)}
          as="image"
          media={`(min-width: ${mubiTheme.responsiveImageBreakpoints.mobile}) and (max-width: ${mubiTheme.responsiveImageBreakpoints.tablet})`}
        />
      )}
      <link
        rel="preload"
        href={getImageUrl(src, sizeInPixels)}
        as="image"
        media={`(max-width: ${mubiTheme.responsiveImageBreakpoints.mobile})`}
      />
    </Head>
  );
};

export default ResponsivePicturePreloadTags;
