import useTranslation from 'next-translate/useTranslation';
import isBot from 'isbot';
import styled from '@emotion/styled';

import { Film } from '@app/api/resources/Film';
import { FilmGroup } from '@app/api/resources/FilmGroup';

import { color as themeColors } from '@app/themes/mubi-theme';

import useIsMounted from '@app/hooks/utils/useIsMounted';

import UnstyledButton from '@app/components/buttons/UnstyledButton';
import DirectorCountryYear from '@app/components/DirectorCountryYear';
import { CloseIcon } from '@app/components/icons/CommonIcons';
import PageSection from '@app/components/layout/PageSection';
import Modal from '@app/components/Modal';
import TrailerPlayer, {
  getIsFilmTrailer,
} from '@app/components/player/TrailerPlayer';
import StopPropagation from '@app/components/StopPropagation';

type TrailerModalProps = {
  closeModal: () => void;
  filmOrFilmGroup: Film | FilmGroup;
  showTrailerModal: boolean;
};

const TrailerModal = ({
  closeModal,
  showTrailerModal,
  filmOrFilmGroup,
}: TrailerModalProps) => {
  const { t } = useTranslation('common');
  const isMounted = useIsMounted();

  // If it's bot, don't render player, as it can report errors to MUX
  const shouldRenderPlayer =
    isMounted && navigator && !isBot(navigator.userAgent);

  const isFilmTrailer = getIsFilmTrailer(filmOrFilmGroup);

  const filmGroup = filmOrFilmGroup as FilmGroup;
  const film = filmOrFilmGroup as Film;

  return (
    <RequiredRootNode>
      <StyledModal
        isOpen={isMounted && showTrailerModal}
        doClose={closeModal}
        adjustForHeader={false}
        withOverlay
        overlayTransparency={0.8}
        alignAtTop
      >
        <ModalContent
          onClick={() => {
            closeModal();
          }}
        >
          <PageSection fullHeight fromBreakpoint="tablet">
            <PageContent>
              <StyledStopPropagationHeader>
                <HeaderTitle>
                  {t(
                    `common:common.buttons.watch_trailer${
                      isFilmTrailer ? '' : '_collection'
                    }`,
                  )}
                </HeaderTitle>

                <CloseButton onClick={closeModal}>
                  <CloseIcon width="18px" color={themeColors.white} />
                </CloseButton>
              </StyledStopPropagationHeader>
              <StyledStopPropagationFilmContent>
                <FilmName>
                  {isFilmTrailer
                    ? film.title_upcase
                    : filmGroup.full_title_upcase}
                </FilmName>
                {isFilmTrailer ? (
                  <DirectorCountryYear film={film} mobileFontSize="14px" />
                ) : null}
              </StyledStopPropagationFilmContent>
              {shouldRenderPlayer && showTrailerModal && (
                <TrailerPlayer
                  modalIsClosed={!showTrailerModal}
                  filmOrFilmGroup={filmOrFilmGroup}
                />
              )}
            </PageContent>
          </PageSection>
        </ModalContent>
      </StyledModal>
    </RequiredRootNode>
  );
};

const RequiredRootNode = styled.div``;

const StyledModal = styled(Modal)`
  padding: 0;
  height: 100vh;
  overflow: auto;
`;

const ModalContent = styled.div`
  width: 100vw;
  color: ${props => props.theme.color.white};
  height: 100vh;
`;

const PageContent = styled.div`
  height: 100%;
  padding: 10px 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
  @media (orientation: portrait) {
    height: 80%;
  }
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    padding: 20px;
    @media (orientation: portrait) {
      height: 100%;
    }
  }
  @media (max-height: 525px) and (min-width: ${props =>
      props.theme.mqNew.tablet}) {
    height: auto;
    padding-bottom: 100px;
  }
  @media (max-height: 650px) and (min-width: ${props =>
      props.theme.mqNew.desktop}) {
    height: auto;
    padding-bottom: 100px;
  }
  @media (max-height: 765px) and (min-width: ${props =>
      props.theme.mqNew.wide}) {
    height: auto;
    padding-bottom: 100px;
  }
`;

const StyledStopPropagationHeader = styled(StopPropagation)`
  display: flex;
  align-items: center;
  padding: 0 0 12px;
`;

const HeaderTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
`;

const StyledStopPropagationFilmContent = styled(StopPropagation)`
  padding: 0 0 15px;
`;

const FilmName = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const CloseButton = styled(UnstyledButton)`
  margin: 0 0 0 auto;
`;

export default TrailerModal;
