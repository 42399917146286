import styled from '@emotion/styled';

const FilmTileItem = styled.li`
  list-style: none;
  width: 100%;
  max-width: ${props => props.theme.grid.standardItemWidth}px;
  height: ${props => props.theme.grid.standardFilmTileHeight}px;
  margin-bottom: ${props => props.theme.grid.gridMarginBottom}px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    width: ${props => props.theme.grid.standardItemWidth}px;

    &:nth-of-type(1n) {
      margin-right: ${props => props.theme.grid.standardGridGap}px;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    &:nth-of-type(2n) {
      margin-right: ${props => props.theme.grid.standardGridGap}px;
    }
    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    &:nth-of-type(3n) {
      margin-right: ${props => props.theme.grid.standardGridGap}px;
    }
    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }

  @media (min-width: ${props => props.theme.mqNew.wideLarge}) {
    &:nth-of-type(4n) {
      margin-right: ${props => props.theme.grid.standardGridGap}px;
    }
    &:nth-of-type(5n) {
      margin-right: 0;
    }
  }
`;

export default FilmTileItem;
