import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Collection } from '@app/api/resources/Collections';
import { Film } from '@app/api/resources/Film';
import { Translate } from '@app/api/utility-types';

import usePopulateUserRatingForFilms from '@app/hooks/populate-store/usePopulateUserRatingForFilms';
import usePopulateUserWatchlistForFilms from '@app/hooks/populate-store/usePopulateUserWatchlistForFilms';

import CollectionFilmTile from '@app/components/collections/CollectionFilmTile';
import TilesList from '@app/components/grid-layout/TilesList';
import PageSection from '@app/components/layout/PageSection';

type CollectionFilmTilesContainerProps = {
  films: Film[];
  collection: Collection;
};

const CollectionFilmTilesContainer = ({
  films,
  collection,
}: CollectionFilmTilesContainerProps) => {
  const { t } = useTranslation('specials');
  const filmIds = films.map(film => film.id);
  usePopulateUserRatingForFilms(filmIds);
  usePopulateUserWatchlistForFilms(filmIds);

  return <PageSection>{getFilmTilesLayout(films, collection, t)}</PageSection>;
};

const getNoFilmsAvailableText = (collectionSlug: string, t: Translate) => {
  if (collectionSlug === 'leaving-soon') {
    return t('specials:specials.leaving_no_films');
  }
  if (collectionSlug === 'your-watchlist') {
    return t('specials:specials.watchlist_no_films');
  }
  return t('specials:specials.special_no_films');
};

const getFilmTilesLayout = (
  films: Film[],
  collection: Collection,
  t: Translate,
) => {
  if (films.length === 0) {
    return (
      <NoFilmsMessage>
        {getNoFilmsAvailableText(collection.slug, t)}
      </NoFilmsMessage>
    );
  }

  return (
    <TilesList>
      {films.map(film => (
        <CollectionFilmTile key={film.id} film={film} collection={collection} />
      ))}
    </TilesList>
  );
};

const NoFilmsMessage = styled.div`
  width: 100%;
  text-align: center;
  font-size: 25px;
  padding-top: 10px;
  padding-bottom: 50px;
`;

export default CollectionFilmTilesContainer;
