import { useContext } from 'react';

import PreviousPagePathContext from '@app/contexts/PreviousPagePathContext';

const usePreviousPath = () => {
  const context = useContext(PreviousPagePathContext);
  return context;
};

export default usePreviousPath;
