import { getCollection } from '@app/api/resources/Collections';

import { ObjectOfStrings } from '@app/types/utility-types';

export const isAMubiReleasesPage = (collectionSlug: string) =>
  collectionSlug.startsWith('mubi-releases');

const initialiseCollections = async (
  httpContext: ObjectOfStrings,
  collectionSlug: string,
) => {
  const { data: collection } = await getCollection({
    httpContext,
    collectionSlug,
  });

  return collection;
};

export default initialiseCollections;
