import { InView } from 'react-intersection-observer';
import styled from '@emotion/styled';

import { Collection } from '@app/api/resources/Collections';
import { Film } from '@app/api/resources/Film';

import CollectionFilmTilesContainer from '@app/components/collections/CollectionFilmTilesContainer';
import Loading from '@app/components/Loading';

type FilmTilePaginationProps = {
  collection: Collection;
  loadNextPage: () => void;
  films: Film[];
  hasLastPageLoaded: boolean;
};

const FilmTilePagination = ({
  collection,
  films,
  hasLastPageLoaded,
  loadNextPage,
}: FilmTilePaginationProps) => {
  const onReachedLastFilmGroupItem = (isVisible: boolean) => {
    if (!isVisible) {
      return;
    }
    if (!hasLastPageLoaded) {
      loadNextPage();
    }
  };

  if (!hasLastPageLoaded && films.length === 0) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <>
      <CollectionFilmTilesContainer films={films} collection={collection} />
      {!hasLastPageLoaded && (
        <InView onChange={onReachedLastFilmGroupItem}>
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        </InView>
      )}
    </>
  );
};

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 149px;

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    height: 217px;
  }
`;

export default FilmTilePagination;
