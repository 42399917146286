import dynamic from 'next/dynamic';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';
import * as Sentry from '@sentry/nextjs';

import { Collection } from '@app/api/resources/Collections';

import initialiseCollections, {
  isAMubiReleasesPage,
} from '@app/services/page-initialisation/collections';
import {
  doRedirect,
  getFullRouteUrl,
  handleCountryPrefixRedirect,
} from '@app/services/routeHelpers';

import { Context } from '@app/types/common';

import useGetI18nCanonicalUrl from '@app/hooks/helpers/useGetI18nCanonicalUrl';
import { getI18nUrlDataFromState } from '@app/hooks/helpers/useI18nUrlData';
import useIsMounted from '@app/hooks/utils/useIsMounted';

import UserStateBanner from '@app/components/banners/UserStateBanner';
import CollectionPageAboveFold from '@app/components/collections/CollectionPageAboveFold';
import { LoadingContainer } from '@app/components/collections/FilmTilePagination';
import Footer from '@app/components/footer/Footer';
import HeaderContainer from '@app/components/layout/HeaderContainer';
import Loading from '@app/components/Loading';
import PlayerOrchestratorContainer from '@app/components/PlayerOrchestratorContainer';
import BannerContainer from '@app/components/promotional-banner/BannerContainer';
import SeoMetaTags from '@app/components/SeoMetaTags';
import SocialMetaTags from '@app/components/SocialMetaTags';
import StandardHeadTags from '@app/components/StandardHeadTags';

const CollectionPageBelowTheFold = dynamic(
  () =>
    import(
      /* webpackChunkName: "CollectionPageBelowTheFold" */
      '@app/components/collections/CollectionPageBelowTheFold'
    ),
  { ssr: false },
);

type CollectionsProps = {
  collection: Collection;
};

const Collections = ({ collection }: CollectionsProps) => {
  const { t } = useTranslation('specials');
  const isMounted = useIsMounted();

  const canonicalUrl = useGetI18nCanonicalUrl(
    `/collections/${collection?.slug}`,
  );

  if (!collection) {
    return null;
  }

  const collectionFilmGroup = collection.film_group;

  const isFilmGroupCollection = !!collectionFilmGroup;
  const isSeries = !!collectionFilmGroup?.is_season;
  const isFilmGroupPublished = !!collectionFilmGroup?.published;

  const { header_image } = collection;
  const pageTitle = isSeries
    ? collection.title
    : t('specials:specials.special_title', {
        title: collection.title,
      });

  return (
    <>
      <PlayerOrchestratorContainer />
      <StandardHeadTags title={`${pageTitle} | MUBI`} />
      <SeoMetaTags canonicalUrl={canonicalUrl} image={header_image} />
      <SocialMetaTags
        title={collection.title}
        url={canonicalUrl}
        image={header_image}
      />
      <HeaderContainer />
      <BannerContainer />
      {isFilmGroupCollection && !isFilmGroupPublished && (
        <UserStateBanner
          messageString="You only see this because you are an admin, this film group is unpublished"
          allowDismiss={false}
        />
      )}
      <CollectionPageAboveFold collection={collection} />
      {!isMounted ? (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      ) : (
        <>
          <Container>
            <CollectionPageBelowTheFold collection={collection} />
          </Container>
          <Footer />
        </>
      )}
    </>
  );
};

Collections.getInitialProps = async (ctx: Context) => {
  const { store, query, isServer, res } = ctx;

  const shouldRedirectAsCountryPrefixInvalidOrMissing =
    handleCountryPrefixRedirect(ctx);

  const currentState = store.getState();

  if (shouldRedirectAsCountryPrefixInvalidOrMissing) {
    doRedirect(
      isServer,
      getFullRouteUrl({
        url: `/collections/${query?.collectionSlug as string}`,
        i18nUrlData: getI18nUrlDataFromState(currentState),
        includeDomain: false,
        queryParams: query as Record<string, string>,
      }),
      res,
    );

    return {};
  }

  let collectionSlug = '';

  try {
    const isLoggedInUserAnAdmin = currentState?.user?.isAdmin;
    const httpContext = currentState?.appState?.httpContext;

    collectionSlug = isAMubiReleasesPage(
      (query?.collectionSlug as string) || '',
    )
      ? 'mubi-releases'
      : (query?.collectionSlug as string) || '';

    const collection = await initialiseCollections(httpContext, collectionSlug);

    if (!isLoggedInUserAnAdmin && collection.film_group?.published === false) {
      return {
        errorStatusCode: 404,
      };
    }

    return {
      collection,
    };
  } catch (error) {
    if (error?.status === 404) {
      return {
        errorStatusCode: 404,
      };
    }

    Sentry.captureMessage(
      `Error attempting to access collection page with slug: "${collectionSlug}".`,
    );
    doRedirect(
      isServer,
      getFullRouteUrl({
        url: '/showing',
        i18nUrlData: getI18nUrlDataFromState(currentState),
      }),
      res,
    );
    return {};
  }
};

const Container = styled.div`
  min-height: 100vh;

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    min-height: 70vh;
  }
`;

export default Collections;
