import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Collection } from '@app/api/resources/Collections';
import { getStillImageBackgroundPosition } from '@app/api/resources/Film';

import { getFullRouteUrl } from '@app/services/routeHelpers';
import {
  getFacebookShareUrl,
  getTwitterShareUrl,
} from '@app/services/social-media';

import { color as themeColors } from '@app/themes/mubi-theme';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';
import useShouldShowBackButton from '@app/onboarding/hooks/useShouldShowBackButton';

import { ObtuseArrowIcon } from '@app/components/icons/ArrowIcons';
import PageSection from '@app/components/layout/PageSection';
import ResponsivePicture from '@app/components/responsive-images/ResponsivePicture';
import SocialButtons from '@app/components/SocialButtons';

type CollectionsStillWithLinksProps = {
  collection: Collection;
};
const CollectionsStillWithLinks = ({
  collection,
}: CollectionsStillWithLinksProps) => {
  const { t } = useTranslation('specials');
  const router = useRouter();
  const i18nUrlData = useI18nUrlData();

  const collectionFilmGroup = collection.film_group;

  const { header_image, slug, title } = collection;

  const focalPoint = collectionFilmGroup?.focal_point;
  const averageColourHex =
    collectionFilmGroup?.average_colour_hex || themeColors.midBackground;
  const imageUrl = collectionFilmGroup?.image || header_image;

  const url = getFullRouteUrl({
    url: `/collections/${slug}`,
    i18nUrlData,
  });
  const shareCopy = t('specials:specials.share_copy', { title });

  const shouldShowBackButton = useShouldShowBackButton({
    allowedBackwardPaths: ['/showing', '/films/[filmSlug]'],
  });

  let backLink = null;

  if (shouldShowBackButton) {
    backLink = (
      <HeaderMetaAnchor onClick={() => router.back()}>
        <StyledObtuseArrowIcon
          color={themeColors.white}
          width="7px"
          direction="left"
        />
        {t('specials:specials.back')}
      </HeaderMetaAnchor>
    );
  }

  return (
    <HeaderContainer bgColor={averageColourHex}>
      <StyledResponsivePicture
        src={imageUrl}
        alt={title}
        sizeInPixels={700}
        responsiveSizesInPixels={{
          desktopAndUp: 1200,
          tablet: 1000,
          mobile: 800,
        }}
        shouldPreload
        objectFit="cover"
        backgroundPosition={getStillImageBackgroundPosition(focalPoint)}
      />
      <BackgroundOpacity />
      <PageSection>
        <HeaderButtonsContainer>
          <LinkContainer>{backLink}</LinkContainer>
          <SocialButtonContainer>
            <SocialButtons
              spaceBetweenIconsMobile="20px"
              spaceBetweenIconsTablet="20px"
              facebookUrl={getFacebookShareUrl(url)}
              twitterUrl={getTwitterShareUrl(url, shareCopy)}
              iconColor={themeColors.white}
            />
          </SocialButtonContainer>
        </HeaderButtonsContainer>
      </PageSection>
    </HeaderContainer>
  );
};

export default CollectionsStillWithLinks;

type HeaderContainerProps = {
  bgColor: string;
};
const HeaderContainer = styled.div<HeaderContainerProps>`
  position: relative;
  background: ${props => props.theme.color.darkText};
  width: 100%;
  height: 308px;
  background-color: ${props => props.bgColor};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    height: 388px;
  }

  @media (min-width: ${props => props.theme.mqNew.wide}) {
    height: 520px;
  }
`;

const StyledResponsivePicture = styled(ResponsivePicture)`
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const BackgroundOpacity = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.375) 0%,
    transparent 50%
  );
`;

const HeaderButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: 23px;
`;

const LinkContainer = styled.div`
  font-size: 14px;
`;

const HeaderMetaAnchor = styled.a`
  color: ${props => props.theme.color.white};
  display: flex;
  text-transform: uppercase;
  align-items: center;
  font-weight: bold;

  &:visited {
    color: ${props => props.theme.color.white};
  }

  &:hover {
    color: ${props => props.theme.color.white};
  }
`;

const StyledObtuseArrowIcon = styled(ObtuseArrowIcon)`
  margin-right: 9px;
`;

const SocialButtonContainer = styled.div``;
