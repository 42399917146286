import { useEffect, useState } from 'react';

import { getFullRouteUrl } from '@app/services/routeHelpers';

import useI18nUrlData from '@app/hooks/helpers/useI18nUrlData';

const useGetI18nCanonicalUrl = (path: string) => {
  const i18nUrlData = useI18nUrlData();

  const [canonicalUrl, setCanonicalUrl] = useState(
    getFullRouteUrl({
      url: path,
      i18nUrlData,
    }),
  );

  useEffect(() => {
    if (path) {
      setCanonicalUrl(
        getFullRouteUrl({
          url: path,
          i18nUrlData,
        }),
      );
    }
  }, [path]);

  return canonicalUrl;
};

export default useGetI18nCanonicalUrl;
