import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import styled from '@emotion/styled';

import { Collection } from '@app/api/resources/Collections';
import { getFilmGroupTrailerUrlAtProfile } from '@app/api/resources/FilmGroup';

import { truncateHtml } from '@app/services/utils';

import { color as themeColors } from '@app/themes/mubi-theme';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';
import useAppSelector from '@app/hooks/utils/useAppSelector';

import TextPlayButton from '@app/components/buttons/TextPlayButton';
import TrailerModal from '@app/components/film-details/TrailerModal';
import { SelectArrowIcon } from '@app/components/icons/ArrowIcons';

type CollectionsTitleWithDescriptionProps = {
  collection: Collection;
};
const CollectionsTitleWithDescription = ({
  collection,
}: CollectionsTitleWithDescriptionProps) => {
  const { t } = useTranslation('common');
  const isAdmin = useAppSelector(state => state.user.isAdmin);
  const [seeFullDescription, setSeeFullDescription] = useState(false);
  const [showTrailerPlayer, setShowTrailerPlayer] = useState(false);

  const { title } = collection;
  const collectionFilmGroup = collection?.film_group;
  const color = collectionFilmGroup?.color || themeColors.midBackground;
  const descriptionHtml =
    collection.description_html || collectionFilmGroup?.description_html || '';

  const isDescriptionShortEnough = descriptionHtml.length < 510;
  const clippedDescription = truncateHtml(descriptionHtml, 400);

  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const trackEvent = (clickType: string) =>
    trackSnowplowClickEvent({
      data: {
        clickType,
      },
      extraData: {
        film_group_id: collectionFilmGroup.id,
        collection_slug: collectionFilmGroup.slug,
      },
    });

  const trailerUrl = getFilmGroupTrailerUrlAtProfile(
    collectionFilmGroup,
    '720p',
  );

  const getDescriptionSection = () => {
    if (descriptionHtml === '') {
      return null;
    }
    if (seeFullDescription || isDescriptionShortEnough) {
      return (
        <FullDescriptionContainer
          color={color}
          dangerouslySetInnerHTML={{
            __html: descriptionHtml,
          }}
        />
      );
    }
    return (
      <>
        <FullDescriptionContainer
          color={color}
          dangerouslySetInnerHTML={{
            __html: clippedDescription,
          }}
        />
        <ReadMoreButton
          onClick={() => {
            setSeeFullDescription(true);
            trackEvent('expand_text');
          }}
        >
          {t('common:common.buttons.read_more')}
          <SelectArrowContainer>
            <SelectArrowIcon width="12px" />
          </SelectArrowContainer>
        </ReadMoreButton>
      </>
    );
  };

  const descriptionSection = getDescriptionSection();

  return (
    <Container>
      <HeaderSubtitle center={descriptionHtml === ''}>
        {isAdmin ? (
          <StyledAdminLink href={`/admin/film_groups/${collection.slug}/edit`}>
            {title}
          </StyledAdminLink>
        ) : (
          <Title>{title}</Title>
        )}
        {collectionFilmGroup && trailerUrl && (
          <TrailerWatchNowButton
            playIconWidth="10px"
            textIconGap="8px"
            color="black"
            text={t('common:common.buttons.watch_trailer')}
            onClick={() => {
              trackEvent('film_group_trailer');
              setShowTrailerPlayer(true);
            }}
          />
        )}
      </HeaderSubtitle>
      <DescriptionSection>{descriptionSection}</DescriptionSection>
      {collectionFilmGroup && trailerUrl && showTrailerPlayer && (
        <TrailerModal
          filmOrFilmGroup={collectionFilmGroup}
          showTrailerModal={showTrailerPlayer}
          closeModal={() => {
            setShowTrailerPlayer(false);
          }}
        />
      )}
    </Container>
  );
};

export default CollectionsTitleWithDescription;

const Container = styled.div`
  min-height: 100px;
  display: flex;
  flex-wrap: wrap;
`;

type HeaderSubtitleProps = {
  center: boolean;
};
const HeaderSubtitle = styled.div<HeaderSubtitleProps>`
  display: flex;

  line-height: 1;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 15px;
  align-self: ${props => (props.center ? 'center' : 'none')};
  margin: ${props => (props.center ? '0 auto' : 'none')};
  width: 100%;
`;

const DescriptionSection = styled.div`
  margin-bottom: ${props => props.theme.grid.gridMarginBottom}px;
`;

const FullDescriptionContainer = styled.div`
  width: 100%;
  font-size: 16px;
  line-height: 1.4;

  a {
    color: ${props => `#${props.color}`};
  }

  p:not(:last-child) {
    padding-bottom: 2em;
  }
`;

const ReadMoreButton = styled.a`
  font-size: 16px;
  color: ${props => props.theme.color.mubiBlue};
  line-height: 1;

  display: inline-block;
  font-weight: bold;
  margin-top: 15px;
  position: relative;
`;

const SelectArrowContainer = styled.div`
  position: absolute;
  bottom: 3px;
  right: -18px;
`;

const StyledAdminLink = styled.a`
  width: 100%;
  color: ${props => props.theme.color.black};
  padding: 0 20px 0 0;

  &:visited {
    color: ${props => props.theme.color.black};
  }
  &:hover {
    color: ${props => props.theme.color.black};
  }
`;

const Title = styled.div`
  padding: 0 20px 0 0;
`;

const TrailerWatchNowButton = styled(TextPlayButton)`
  padding: 0 11px 0 9px;
  height: 26px;
  font-size: 12px;
  margin: 0 0 0 auto;
  white-space: nowrap;
`;
