import { isSafari } from 'react-device-detect';

import { PlayerConfig } from '@app/services/clpp';
import {
  capturePrestoPlayerError,
  clientSupportsHLS,
} from '@app/services/player';
import { Observable } from '@app/services/utils';

const SUBTITLE_FONT_SIZE = '12px';

const playerConfig: PlayerConfig = {
  enableHtmlCue: true,
  preferredVideoCodec: isSafari ? 'hvc1' : 'vp09',
  manifest: {
    attemptParameters: {
      maxAttempts: 5,
      baseDelay: 1000,
      backoffFactor: 2,
      fuzzFactor: 0.5,
      timeout: 0,
    },
  },
  streaming: {
    bufferingGoal: 120, // buffer at most 120 seconds
    rebufferingGoal: 3, // allow playback to start once we have 3 seconds of data
    bufferBehind: 5, // keep 5 seconds of data before the current playhead
    attemptParameters: {
      maxAttempts: 10,
      baseDelay: 1000,
      backoffFactor: 2,
      fuzzFactor: 0.5,
      timeout: 0,
    },
  },
  license: process.env.PRESTOPLAY_LICENSE_KEY,
  abr: {
    restrictions: {
      maxHeight: 250,
    },
  },
  textStyle: {
    fontSize: SUBTITLE_FONT_SIZE,
  },
};

const toState = (state: number) => {
  // https://demo.castlabs.com/#/docs?q=clpp.Player#State
  switch (state) {
    case 0:
      return 'idle';
    case 1:
      return 'preparing';
    case 2:
      return 'buffering';
    case 3:
      return 'playing';
    case 4:
      return 'paused';
    case 5:
      return 'ended';
    case 6:
      return 'error';
    case 7:
      return 'unset';
    default:
      return 'unset';
  }
};

type PlayerEvent =
  | 'play'
  | 'error'
  | 'statechanged'
  | 'texttrackchanged'
  | 'videotrackchanged'
  | 'loadstart'
  | 'releasing'
  | 'released'
  | 'destroying'
  | 'destroyed';

type Track = {
  id: string;
  type: string;
  roles: string[];
  kind: string;
  language: string;
  label: string;
  frameRate: number;
  mimeType: string;
  src: string;
  channelsCount: number;
};

type PlayerError = {
  severity: number;
  category: number;
  code: number;
};

type LoadConfig = {
  source: { url: string; type: string }[];
  isMuted: boolean;
  preferredTextLanguage?: string;
};

export default class PlayerInterface {
  private _player = null;
  private _config = null;
  private _eventEmitter = new Observable();

  async init(element: HTMLVideoElement, config?: PlayerConfig) {
    if (this._player) {
      await this.destroy();
    }
    return new Promise<void>(async (resolve, reject) => {
      try {
        const clpp = (await import('@app/services/clpp')).default as any;
        this._config = Object.assign({}, playerConfig, config);
        this._player = new clpp.Player(element, this._config, {
          containerEl: element.parentElement,
        });
        if (process.env.MUBI_ENV === 'production') {
          clpp.log.setLogLevel(clpp.log.Level.WARNING);
        }

        this._player.on('error', this._reportError);
        this._player.use(clpp.htmlcue.HtmlCueComponent);
        this._player.use(clpp.ttml.TtmlComponent);
        this._player.use(clpp.vtt.VttComponent);
        if (clientSupportsHLS()) {
          this._player.use(clpp.hls.HlsComponent);
        } else {
          this._player.use(clpp.dash.DashComponent);
        }
        this._player.on(clpp.events.STATE_CHANGED, e => {
          let currentState = toState(e.detail.currentState);
          let previousState = toState(e.detail.previousState);
          this._emit('statechanged', {
            currentState: currentState,
            previousState: previousState,
          });
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async load({
    source,
    isMuted,
    preferredTextLanguage,
  }: LoadConfig): Promise<void> {
    if (!this._player) {
      return;
    }
    await this._player.release();
    return new Promise(async (resolve, reject) => {
      try {
        await this._player.load({
          source,
          muted: isMuted,
          preferredTextLanguage,
        });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async doPlay({ isMuted }): Promise<void> {
    if (!this._player) {
      return;
    }
    return new Promise(async (resolve, reject) => {
      try {
        await this._player.play({ muted: isMuted });
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }

  async pause(): Promise<void> {
    if (!this._player) {
      return;
    }
    await this._player.pause();
  }

  setTextTrack(currentLang: string) {
    if (!this._player) {
      return;
    }
    const trackManager = this._player.getTrackManager();
    const textTracks: Track[] = trackManager.getTextTracks();
    let matchingTrack = textTracks.find(
      track => track.language === currentLang,
    );
    if (!matchingTrack) {
      matchingTrack = textTracks.find(track => track.language === 'en');
    }
    trackManager.setTextTrack(matchingTrack);
  }

  clearTextTrack() {
    if (!this._player) {
      return;
    }
    const trackManager = this._player.getTrackManager();
    trackManager.setTextTrack(null);
  }

  on(type: PlayerEvent, listener: ({ currentState, previousState }) => void) {
    this._eventEmitter.on(type, listener);
  }

  off(type: PlayerEvent, listener: ({ currentState, previousState }) => void) {
    this._eventEmitter.off(type, listener);
  }

  get muted(): boolean {
    if (this._player) {
      return this._player.isMuted();
    }
    return false;
  }

  set muted(muted: boolean) {
    if (this._player) {
      this._player.setMuted(muted);
    }
  }

  get currentTime() {
    if (this._player) {
      return this._player.getPosition();
    }
  }

  async destroy(): Promise<void> {
    if (this._player) {
      this._player.off('error', this._reportError);
      await this._player.destroy();
      this._player = null;
    }
  }

  private _reportError(
    error: {
      severity: number;
      detail: PlayerError;
    },
    extraErrorTags,
  ) {
    const errorSeverity =
      error?.detail?.severity || error?.severity || 'UNKNOWN SEVERITY';
    if (errorSeverity === 1) {
      return;
    }
    capturePrestoPlayerError(error, extraErrorTags);
  }

  private _emit(type: PlayerEvent, data?) {
    this._eventEmitter.notify(type, data);
  }

  get isPlayerInitialized(): boolean {
    return this._player !== null;
  }
}
