import { ReactNode } from 'react';
import styled from '@emotion/styled';

type TilesListProps = {
  children?: ReactNode;
  className?: string;
};

const TilesList = ({ className = '', children = null }: TilesListProps) => (
  <List className={className}>{children}</List>
);

export default TilesList;

const List = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
`;
