import styled from '@emotion/styled';

import { Collection } from '@app/api/resources/Collections';

import CollectionsStillWithLinks from '@app/components/collections/CollectionsStillWithLinks';
import CollectionsTitleWithDescription from '@app/components/collections/CollectionsTitleWithDescription';
import PageSection from '@app/components/layout/PageSection';

type CollectionApiPageProps = {
  collection: Collection;
};

const CollectionPageAboveFold = ({ collection }: CollectionApiPageProps) => {
  return (
    <>
      <CollectionsStillWithLinks collection={collection} />
      <PageSection>
        <CenteredSectionContainer>
          <CollectionsTitleWithDescription collection={collection} />
        </CenteredSectionContainer>
      </PageSection>
    </>
  );
};

export default CollectionPageAboveFold;

const CenteredSectionContainer = styled.div`
  @media (min-width: ${props => props.theme.mqNew.tablet}) {
    max-width: 780px;
    margin: 0 auto;
  }

  @media (min-width: ${props => props.theme.mqNew.desktop}) {
    padding: 0 15px;
  }
`;
