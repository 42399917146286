import { Collection } from '@app/api/resources/Collections';
import { Film, getIsWatchable } from '@app/api/resources/Film';

import useSnowplowClickTracker from '@app/hooks/snowplow/useSnowplowClickTracker';

import {
  PLAYABLE_FILM_TILE_SNOWPLOW_ELEMENT,
  UNPLAYABLE_FILM_TILE_SNOWPLOW_ELEMENT,
} from '@app/components/film-tile/default/FilmTile';
import FilmTileWithImpressionTracking from '@app/components/film-tile/default/FilmTileWithImpressionTracking';
import FilmTileItem from '@app/components/grid-layout/FilmTileItem';

type CollectionFilmTileProps = {
  film: Film;
  collection: Collection;
};

const CollectionFilmTile = ({ film, collection }: CollectionFilmTileProps) => {
  const trackSnowplowClickEvent = useSnowplowClickTracker();

  const trackEvent = (clickType: string) => {
    const isWatchable = getIsWatchable(film);

    trackSnowplowClickEvent({
      data: {
        clickType,
        element: isWatchable
          ? PLAYABLE_FILM_TILE_SNOWPLOW_ELEMENT
          : UNPLAYABLE_FILM_TILE_SNOWPLOW_ELEMENT,
      },
      extraData: {
        film_group_id: collection.film_group?.id,
        collection_slug: collection.slug,
      },
      film,
    });
  };

  return (
    <FilmTileItem key={film.id}>
      <FilmTileWithImpressionTracking
        film={film}
        onTileClick={() => trackEvent('film_tile')}
        onPlayButtonClick={() => trackEvent('film_play')}
      />
    </FilmTileItem>
  );
};

export default CollectionFilmTile;
