import HttpService from '@app/api/http-service';
import { MetaCursor } from '@app/api/pagination';
import { Film, FilmId } from '@app/api/resources/Film';
import { ResponseWithError } from '@app/api/resources/Responses';
import { User } from '@app/api/resources/User';
import { ObjectOfStrings } from '@app/api/utility-types';
import { getCursorPaginationQS } from '@app/api/utils';

export type RatingId = number;

export type Rating = {
  id: RatingId;
  film_id: number;
  film: Film;
  body: string;
  overall: number;
  like_count: number;
  comment_count: number;
  liked: boolean;
  updated_at: string;
  created_at: string;
  canonical_url: string;
  user: User;
  seo_indexable: boolean;
};

const getFilmIdsQueryString = (filmIds: FilmId[]) => {
  if (filmIds.length > 0) {
    return `?film_ids[]=${filmIds.join('&film_ids[]=')}`;
  }
  return '';
};

export const createUpdateRating = (
  httpContext: ObjectOfStrings,
  filmId: number,
  rating: string = null,
  body: string = null,
): Promise<{
  data: Rating;
}> => {
  const payload: {
    rating?: string;
    body?: string;
  } = {};
  if (rating !== null) {
    payload.rating = rating;
  }
  if (body !== null) {
    payload.body = body;
  }
  return HttpService(httpContext).put(`/ratings/${filmId}`, { data: payload });
};

export const deleteRating = (
  httpContext: ObjectOfStrings,
  filmId: number,
): Promise<{
  status: 204;
}> => HttpService(httpContext).deleteRequest(`/ratings/${filmId}`);

export const getRatingsForFilmsByFilmId = (
  httpContext: ObjectOfStrings,
  filmId: number,
  page = 1,
  pageSize = 8,
): Promise<{
  data: Rating[];
}> =>
  HttpService(httpContext).get(
    `/ratings/${filmId}/ranked?page=${page}&per_page=${pageSize}&x=1`,
  );

export const getRatingsForFilmsFromCurrentUserByFilmIds = (
  httpContext: ObjectOfStrings,
  filmIds: number[] = [],
): {
  data: Rating[];
} => {
  const filmIdQueryString = getFilmIdsQueryString(filmIds);
  return HttpService(httpContext).get(`/user/ratings${filmIdQueryString}`);
};

export type UserRatingsResponse = {
  ratings: Rating[];
  meta: MetaCursor;
};

export const getRatingsOfUser = ({
  httpContext,
  userId,
  currentCursor,
  perPage,
}: {
  httpContext: ObjectOfStrings;
  userId: number;
  currentCursor: string;
  perPage: number;
}): Promise<{
  data: UserRatingsResponse;
}> =>
  HttpService(httpContext).get(
    `/users/${userId}/ratings?${getCursorPaginationQS(currentCursor, perPage)}`,
  );

export const createRatingReport = (
  httpContext: ObjectOfStrings,
  ratingId: RatingId,
): ResponseWithError<{
  id: number;
}> => HttpService(httpContext).post(`/ratings/${ratingId}/flagging`);

export const getRatingReport = (
  httpContext: ObjectOfStrings,
  ratingId: RatingId,
): ResponseWithError<{
  id: number;
}> => HttpService(httpContext).get(`/ratings/${ratingId}/flagging`);
